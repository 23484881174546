import { MarkenGrid } from "./MarkenGrid"
import React  from 'react';
import { useTranslation } from 'react-i18next'

export const Gallery = (props) => {
  const { t } = useTranslation()
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{t("Marken")}</h2>
          <p>
          {t("Wir bieten eine große Auswahl an renomierten Maschinen-Marken für die Holzverarbeitung an." )}
          </p>
        </div>
        <MarkenGrid></MarkenGrid>
      </div>
    </div>
  )
}
