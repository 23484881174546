import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { MarkenGrid } from './MarkenGrid';
import React  from 'react';
import { useTranslation } from 'react-i18next'

export function Ersatzteile(props) {
    const { t } = useTranslation()
    return (
        <div className="container">
            <div className="text-center section-title">
                <h2>{t("Ersatzteile")}</h2>
                <p>{t("Wir haben ein großes Ersatzteillager für Maschinen jeglicher Art")}</p>
                <p>{t("Kontaktieren Sie uns")}</p>
                <div className='row' style={{marginBottom:"60px"}}>
                    <div className='contact-item' style={{"display":"flex", "justifyContent":"center"}} >
                        <a className='anfrage-link' href={"mailto: organisation@mimac.it?subject=" + encodeURI(t("Ersatzteil Anfrage")) + "&body=" + encodeURIComponent(t("Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot."))}>
                            <div className='anfrage-button '>
                                <i className='fa fa-envelope-o'></i> {t("E-Mail Ersatzteil-Angebot anfragen")}
                            </div>
                        </a>
                    </div>

                    <div className='contact-item' style={{"display":"flex", "justifyContent":"center"}} >
                        <a className='anfrage-link' href={"https://wa.me/+393356770348?text=" + encodeURIComponent(t("Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot."))} target="_blank" rel="noopener noreferrer">
                            <div className='anfrage-button '>
                                <i className='fa fa-whatsapp'></i> {t("WhatsApp Ersatzteil-Angebot anfragen")}
                            </div>
                        </a>
                    </div>

                    <div className='contact-item' style={{marginTop:"20px"}}>
                        <p>
                            <span>
                                <i className='fa fa-phone'></i> {t("Telefon")}
                            </span>{' '}
                            <a href="tel:+39 3356770348">+39 3356770348</a>
                        </p>
                    </div>
                    <div className='contact-item'>
                        <p>
                            <span>
                                <i className='fa fa-envelope-o'></i> {t("E-Mail")}
                            </span>{' '}
                            <a href={"mailto: organisation@mimac.it?subject=" + encodeURI("Ersatzteil Anfrage") + "&body=" + encodeURIComponent(t("Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot."))}>organisation@mimac.it</a>
                        </p>
                    </div>
                    {/* <div className='contact-item'>
                        <p>
                            <i className='fa fa-whatsapp'></i>
                            <a href={"https://wa.me/+390471971561?text="+encodeURI("Ich habe Interesse an einem Ersatzteil")} target="_blank" rel="noopener noreferrer"> WhatsApp Nachricht</a>
                        </p>
                    </div> */}
                </div>
                <MarkenGrid></MarkenGrid>
            </div>
        </div>

    )
}