import React from 'react';
import './Maschinen.css';
import { MaschinenDetails } from './MaschinenDetails'
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { MaschinenGrid } from './MaschinenGrid';
import { useTranslation } from 'react-i18next'



export function Maschinen(props) {
  let { path } = useRouteMatch();

  return (
    <div className="container marginbm ">
      <Switch>
        <Route path={`${path}/:mid`}>
          <MaschinenDetails maschinen={props.maschinen} path={props.path}></MaschinenDetails>
        </Route>
        <Route exact path={path}>
          <MaschinenGrid {...props}></MaschinenGrid>
        </Route>
      </Switch>
    </div>
  );
}