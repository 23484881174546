import {
  Link,
} from "react-router-dom";
import React  from 'react';
import { useTranslation } from 'react-i18next'

export const Contact = (props) => {
  const { t } = useTranslation()
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className="row">
            <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>{t("Informationen")}</h3>
              <span>
                <i className='fa fa-clock-o'></i> {t("Öffnungszeiten")}
              </span>{' '}
              <p><b>{t("Montag - Donnerstag")}</b>:</p>
              <p> 8:00 - 12:00 &amp; 14:00 - 18:00</p>
              <p><b>{t("Freitag")}</b>:<br /></p>
              <p>8:00 - 12:00 <br /></p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> {t("Addresse")}
                </span>
                {t("Schlachthofstrasse 91 \n 39100 - Bozen (Südtirol) ")}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> {t("Telefon")}
                </span>{' '}
                <a href="tel:+39 0471 971561" className="contact-link">+39 0471 971561</a>
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> {t("E-Mail")}
                </span>{' '}
                <a href="mailto: home@mimac.it" className="contact-link">home@mimac.it</a>
                
              </p>
            </div>
          </div>
            <div className='col-md-8 contact-info'>
              <a href='https://goo.gl/maps/Bp32qtKyS87i27A66'>
                <img src='img/maps.png' className="img-fluid" alt='Location on map' />
              </a>
            </div>
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p> <Link to="/impressum">{t("Impressum")}</Link>
            &copy; 2023 - {new Date().getFullYear()} OMU Productions{' '}
            {/* <a href='' rel='nofollow'>
              
            </a> */}
          </p>
        </div>
      </div>
    </div>
  )
}
