import React from 'react';
import {
  useLocation
} from "react-router-dom";
import { MaschinenGrid} from './MaschinenGrid';
import { Unterseiten } from './MaschinenDetails';

export function SearchResults(props) { 
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    function suche(maschinen, text) {
        return maschinen.filter(m => contains(m, text));
    }
    function contains(maschine, text) {
        for (const [key, value] of Object.entries(maschine)) {
            console.log(`Hallo welt ${text} ${key}: ${value}`);
            if (value.toLowerCase().includes(text))
                return true;
        }
        return false;
    }
    
    return (
        <div className="container marginbm ">
            {/* <h1>Suchergebnis</h1> */}
            <MaschinenGrid maschinen={props.maschinen} categories={props.categories} marken={props.marken} path="/maschinen" unterseite={Unterseiten.Maschinen}/>
            <MaschinenGrid maschinen={props.produkte["Kleinmaschinen"].produkte} categories={props.produkte["Kleinmaschinen"].kategorien} marken={props.produkte["Kleinmaschinen"].hersteller} path="/werkzeug" unterseite={Unterseiten.Kleinmaschinen}/>
            <MaschinenGrid maschinen={props.produkte["Sonstiges"].produkte} categories={props.produkte["Sonstiges"].kategorien} marken={props.produkte["Sonstiges"].hersteller} path="/sonstiges" unterseite={Unterseiten.Sonstiges}/>
            <MaschinenGrid maschinen={props.produkte["Absauganlagen"].produkte} categories={props.produkte["Absauganlagen"].kategorien} marken={props.produkte["Absauganlagen"].hersteller} path="/absauganlagen" unterseite={Unterseiten.Absauganlagen}/>
            {/* <MaschinenGrid maschinen={produkte["Kleinmaschinen"].produkte} categories={produkte["Kleinmaschinen"].kategorien} marken={produkte["Kleinmaschinen"].hersteller} path="/sonstiges" unterseite={Unterseiten.Kleinmaschinen}/>
            <MaschinenGrid maschinen={produkte["Kleinmaschinen"].produkte} categories={produkte["Kleinmaschinen"].kategorien} marken={produkte["Kleinmaschinen"].hersteller} path="/absauganlagen" unterseite={Unterseiten.Kleinmaschinen}/> */}
        </div>
    )
}