import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
    useHistory
} from "react-router-dom";

export function MaschinenAlbum(props) {
    let { path, url } = useRouteMatch();
    const history = useHistory();

    function callDetails() {
    //     history.push(`${props.path}/${props.i.id}`);
    }

    function preis(i) {
        if (i["Verkaufspreis"]) {
            if (i["angebot_aktiv"] && i["Angebotspreis"]) {
                return i["Angebotspreis"] + " €*"
            }
            return i["Verkaufspreis"] + " €*"
        }
        return ""
    }

    function albumSize(props) {
        if (props.slider) {
            if (props.pos === 0) {
                return "col-lg-3 col-xl-2";
            } else {
                return "col-lg-4 col-xl-2";
            }
        } else {
            if (props.pos === 0) {
                return "col-md-3";
            } else {
                return "col-md-4";
            }
        }
    }

    return (
        <div className={albumSize(props)} style={{ flex: "0 0 auto", position: "relative" }}>
            <Link to={props.path + "/" + props.i.id} style={{"all": "unset"}}>
                {props.i["angebot_aktiv"] && <span className='card-sale'>Angebot</span>}
                <div className="card shadow-sm custom-card card-album cursorp h-35" onClick={() => callDetails(props)}>
                    <img
                    srcSet={`${props.i.thumb} 1x, ${props.i.thumb2x} 2x, ${props.i.thumb3x} 3x`}
                    className="card-image" src={props.i.thumb} height="225" alt="Image of the machine" />
                    <div className="card-body">
                        <p className="card-text">{props.i["Bezeichnung"]}{props.i["Lieferstatus"] && <span className={props.i["Lieferstatus"] === "Lagernd" ? "green-dot" : "red-dot"}>●</span>}</p>

                        <p className="card-text text-secondary card-preis">{preis(props.i)}</p>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="btn-group">
                                {/* <button type="button" className="btn btn-sm btn-outline-secondary" onClick={e => { setDetailItem(props.i); setDetail(true); }}>Details</button> */}
                                {/* <button type="button" className="btn btn-sm btn-outline-secondary">
                <Link to={`${url}/${props.i.id}`} onClick={e => { setDetailItem(props.i); setDetail(true); }}>Details</Link>
            </button> */}
                                {/* <Link className="btn btn-sm btn-outline-secondary" to={`${url}/${props.i.id}`} onClick={e => { setDetailItem(props.i); setDetail(true); }}>Details</Link> */}

                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}