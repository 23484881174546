import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'


i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'de',
    debug: true,
    resources: {
      de: {
        translation: {
          // App
          "Maschinen im Angebot": "Maschinen im Angebot",
          "Es gab noch nie": "Es gab noch nie so einen guten Moment Ihre alte durch eine neue zu ersetzen",
          "Beliebtesten Maschinen": "Beliebtesten Maschinen",
          "Wir bieten eine": "Wir bieten eine große Auswahl an neuen und gebrauchten Maschinen",
          "Neusten Maschinen": "Neusten Maschinen",
          "Maschinen frisch aus der Fabrik!": "Maschinen frisch aus der Fabrik!",
          "Cookie MSG": "Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.",
          "Werkzeug im Angebot": "Werkzeug im Angebot",

          "Es gab noch nie so einen guten Moment jetzt zuzugreifen": "Es gab noch nie so einen guten Moment jetzt zuzugreifen",
          "Topseller Werkzeug": "Topseller Werkzeug",
          "Mit diesem Werkzeug kann man nicht viel falsch machen": "Mit diesem Werkzeug kann man nicht viel falsch machen",
          "Neustes Werkzeug": "Neustes Werkzeug",
          "Nigelnagelneues Werkzeug direkt von der Werkbank": "Nigelnagelneues Werkzeug direkt von der Werkbank",
          "Mimac - Holzverarbeitungsmachinen, Kantenanleimmaschinen, Langbandschleifmaschinen in Südtirol Italien": "Mimac - Holzverarbeitungsmachinen, Kantenanleimmaschinen, Langbandschleifmaschinen in Südtirol Italien",

          //About us 
          "Unsere Firmengeschichte": "Unsere Firmengeschichte",
          "1958 beschloss Albert Micheli": "1958 beschloss Albert Micheli nach abgeschlossener Lehre und einiger Erfahrung mit dem Verkauf von Maschinen für Handwerk und Industrie, seine Geschäfte selbst in die Hand zu nehmen und eine eigene Firma zu gründen. So entstand in der Rittner Straße in Bozen ein kleines Geschäft für die Vermarktung von hochqualitativen Maschinen und Geräten für die Holzverarbeitung, das 1966 in die Andreas-Hofer-Straße erweitert werden musste. 1973 folgte die Übersiedelung in die Schlachthofstraße 91-93, wo sich noch heute der Firmensitz befindet. Gleichzeitig engagierte sich der aktive junge Unternehmer, der auch in der Kolpingfamilie tätig war, mit einigen Freunden, darunter Holzfachexperte Karl Pichler, für die Ausbildung vom Nachwuchs im Bereich Holz und unterstützte die Errichtung der Fachschule für maschinelle Holzverarbeitung in Meran. Albert Micheli, der eine ausgesprochen zugängliche und charmante Persönlichkeit war, zeigte sich schon in den 60er- und 70er-Jahren innovativ und baute das Image seines Betriebes auf die persönliche Kundenbindung. Bald wurde die Firma Micheli Maschinen für alle im Holzsektor tätigen Handwerker ein Begriff für Auswahl, kompetente Beratung, Spitzenqualität und verlässliche Serviceleistung.",
          "Ein Schicksalsschlag": "Ein Schicksalsschlag",
          "Doch genau im Jahr des 30.": "Doch genau im Jahr des 30. Firmenjubiläums, also 1988, erlag der sportliche Albert Micheli ganz plötzlich einem Herzinfarkt. Seine 3 Kinder waren noch im Schulalter. Die große Lücke, die er hinterließ, musste seine Frau nun füllen. Ob sie das wohl konnte? Sie war zwar stetig an der Seite ihres Mannes im Geschäft tätig gewesen, doch die Führungsrolle zu übernehmen, war eine große Verantwortung. Ermutigt durch die bewiesene Treue der vielen Kunden entschied sich Frau Micheli, unterstützt von den treuen Mitarbeitern, doch noch den Betrieb weiterzuführen.",
          "Mimac - Micheli Maschinen heute": "Mimac - Micheli Maschinen heute",
          "Dass dies die richtige Entscheidung war": "Dass dies die richtige Entscheidung war, beweisen die Ergebnisse und die Entwicklung der Firma Micheli Maschinen. Heute ist die Firma Micheli Maschinen weit über die Grenzen Südtirols Leader für die Vermarktung von Maschinen und Geräten für die Holzverarbeitung. Fachkundige Mitarbeiter,  Vertreter und technische Berater, geführt von Frau Micheli mit großer Unterstützung durch Sohn Arno, bieten die größte Auswahl an hochtechnologischen Anlagen für anspruchsvolle Handwerker, aber auch für geschickte Heimbastler.",

          //Navbar
          "Maschinen": "Maschinen",
          "Kleinmaschinen": "Kleinmaschinen",
          "Ersatzteile": "Ersatzteile",
          "Kontakt": "Kontakt",
          "Suche...": "Suche...",


          //GridMenu
          "Neumaschinen": "Neumaschinen",
          "Gebrauchtmaschinen": "Gebrauchtmaschinen",
          "Ersatzteile": "Ersatzteile",
          "Service - Reparaturen": "Service - Reparaturen",
          "Kleinmaschinen": "Kleinmaschinen",
          "Sonstiges": "Sonstiges",
          "Absauganlagen": "Absauganlagen",
          "Über uns - Kontakt": "Über uns - Kontakt",

          //Services
          "Kundenservice": "Kundenservice",
          "Service für Ersatzteile, Reparaturen, Schulungen, Planungen, Schleifdienst und vieles mehr.": "Service für Ersatzteile, Reparaturen, Schulungen, Planungen, Schleifdienst und vieles mehr.",
          "Reparaturen": "Reparaturen",
          "Wir kümmern uns um Sie auch nach dem Verkauf!": "Wir kümmern uns um Sie auch nach dem Verkauf!",
          "Kurse und Schulungen": "Kurse und Schulungen",
          "Damit Ihre Mitarbeiter immer auf dem neuesten Stand sind.": "Damit Ihre Mitarbeiter immer auf dem neuesten Stand sind.",
          "Projektplanungen": "Projektplanungen",
          "Planen Sie in Ihrer Werkstatt etwas zu ändern? Wir unterstützen Sie von der Idee bis zur Umsetzung.": "Planen Sie in Ihrer Werkstatt etwas zu ändern? Wir unterstützen Sie von der Idee bis zur Umsetzung.",
          "Lieferservice": "Lieferservice",
          "Wir bieten einen schnellen Lieferservice.": "Wir bieten einen schnellen Lieferservice.",
          "Kompetente Beratungen": "Kompetente Beratungen",
          "Kompetente Beratung seit über 50 Jahren.": "Kompetente Beratung seit über 50 Jahren.",
          "Wir bieten": "Wir bieten",


          //Marken
          "Marken": "Marken",
          "Wir bieten eine große Auswahl an renomierten Maschinen-Marken für die Holzverarbeitung an.": "Wir bieten eine große Auswahl an renomierten Maschinen-Marken für die Holzverarbeitung an.",

          //Kontakt
          "Informationen": "Kontaktinformationen",
          "Montag - Donnerstag": "Montag - Donnerstag",
          "Freitag": "Freitag",
          "Addresse": "Addresse",
          "Schlachthofstrasse 91 \n 39100 - Bozen (Südtirol) ": "Schlachthofstraße 91, 39100 Bozen (Südtirol)",
          "Öffnungszeiten": "Öffnungszeiten",
          "Telefon": "Telefon",
          "Impressum": "Impressum",
          "E-Mail": "E-Mail",

          //Ersatzteile
          "Ersatzteile": "Ersatzteile",
          "Wir haben ein großes Ersatzteillager für Maschinen jeglicher Art": "Wir haben ein großes Ersatzteillager für Maschinen jeglicher Art",
          "Kontaktieren Sie uns": "Kontaktieren Sie uns",
          "Ersatzteil Anfrage": "Ersatzteil Anfrage",
          "Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot.": "Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot.",
          "E-Mail Ersatzteil-Angebot anfragen": "E-Mail Ersatzteil-Angebot anfragen",
          "WhatsApp Ersatzteil-Angebot anfragen": "WhatsApp Ersatzteil-Angebot anfragen",

          //MaschinenDetails
          "Art.-Nr.": "Art.-Nr.",
          "Hersteller": "Hersteller",
          "Modell": "Modell",
          "Kategorie": "Kategorie",
          "Gebrauchte Maschine": "Gebrauchte Maschine",
          "Neue Maschine": "Neue Maschine",
          "Artikelnummer": "Artikelnummer",
          "Lieferstatus": "Lieferstatus",
          "Wähle eine Variante": "Wähle eine Variante",
          "inkl. gesetzl. MwSt. 22%": "inkl. gesetzl. MwSt. 22%",
          "Dokumente": "Dokumente",
          "Angebots Anfrage: ": "Angebots Anfrage: ",
          "Ich habe interesse an: ": "Ich habe interesse an: ",
          "Ich bitte um ein Angebot.": "Ich bitte um ein Angebot.",
          "E-Mail Angebot anfragen": "E-Mail Angebot anfragen",
          "WhatsApp Angebot anfragen": "WhatsApp Angebot anfragen",
          "Zubehör auf Anfrage": "Zubehör auf Anfrage",
          "Ähnliche Produkte": "Ähnliche Produkte",
          "Andere Produkte von ": "Andere Produkte von ",
          "Mit folgenden Optionals": "Mit folgenden Optionals",
          "Lagernd": "Lagernd",
          "Nicht lagernd": "Nicht lagernd",
          "Mehr": "Mehr",

          //MaschinenGrid
          "Alle Kategorien": "Alle Kategorien",
          "Alle Hersteller": "Alle Hersteller",
          "Gebrauchte Maschinen": "Gebrauchte Maschinen",
          "Neue Maschinen": "Neue Maschinen",
          "Alle Kleinmaschinen": "Alle Kleinmaschinen",
          "Alle Maschinen": "Alle Maschinen",
          "Neue Kleinmaschinen": "Neue Kleinmaschinen",
          "Neue Maschinen": "Neue Maschinen",
          "Gebrauchte Kleinmaschinen": "Gebrauchte Kleinmaschinen",
          "Gebrauchte Maschinen": "Gebrauchte Maschinen",
          "Sortieren": "Sortieren",
          "Neuheiten": "Neuheiten",
          "Beliebt": "Beliebt",
          "Angebote": "Angebote",
          "Hersteller": "Hersteller",
          "Kategorie": "Kategorie",
          "Keine Kleinmaschinen gefunden": "Keine Kleinmaschinen gefunden",
          "Keine Maschinen gefunden": "Keine Maschinen gefunden",
          "Keine Produkte gefunden": "Keine Produkte gefunden",

          //Maschinen
          "Top Werkzeug": "Top Werkzeug",
          "Beliebte Maschinen": "Beliebte Maschinen",
          "Beliebte Produkte": "Beliebte Produkte",

          //Popup
          "Nicht mehr anzeigen": "Nicht mehr anzeigen",

        }
      },
      en: {
        translation: {
          "Maschinen im Angebot": "Machines on offer",
          "Es gab noch nie": "There has never been a better time to replace your old one with a new one",
          "Beliebtesten Maschinen": "Most popular machines",
          "Wir bieten eine": "We offer a large selection of new and used machines",
          "Neusten Maschinen": "Latest machines",
          "Maschinen frisch aus der Fabrik!": "Machines fresh from the factory!",
          "Cookie MSG": "This website uses cookies to improve user experience.",
          "Werkzeug im Angebot": "Tools on offer",
          "Es gab noch nie so einen guten Moment jetzt zuzugreifen": "There has never been a better time to grab it now",
          "Topseller Werkzeug": "Top selling tools",
          "Mit diesem Werkzeug kann man nicht viel falsch machen": "You can't go wrong with these tools",
          "Neustes Werkzeug": "Latest tools",
          "Nigelnagelneues Werkzeug direkt von der Werkbank": "Brand new tools straight from the workbench",
          "Mimac - Holzverarbeitungsmachinen, Kantenanleimmaschinen, Langbandschleifmaschinen in Südtirol Italien": "Mimac - wood processing machines, edge banding machines, long belt sanding machines in South Tyrol Italy",

          "Unsere Firmengeschichte": "Our company history",
          "1958 beschloss Albert Micheli": "In 1958, after completing his apprenticeship and gaining some experience in selling machines for trade and industry, Albert Micheli decided to take his business into his own hands and set up his own company. A small store for the marketing of high-quality woodworking machinery and equipment was set up in Rittner Strasse in Bolzano, which had to be expanded to Andreas-Hofer-Strasse in 1966. This was followed in 1973 by the move to Schlachthofstrasse 91-93, where the company headquarters are still located today. At the same time, the active young entrepreneur, who was also active in the Kolping family, and some friends, including wood expert Karl Pichler, were committed to training young people in the wood sector and supported the establishment of the technical college for mechanical wood processing in Merano. Albert Micheli, who was an extremely approachable and charming personality, was already innovative in the 1960s and 1970s and built the image of his company on personal customer loyalty. Micheli Maschinen soon became a byword for choice, expert advice, top quality and reliable service for all craftsmen working in the wood sector.",
          "Ein Schicksalsschlag": "A stroke of fate",
          "Doch genau im Jahr des 30.": "But in 1988, the year of the company's 30th anniversary, the sporty Albert Micheli suddenly died of a heart attack. His 3 children were still of school age. The huge gap he left behind had to be filled by his wife. Could she do it? She had always worked alongside her husband in the business, but taking on the leadership role was a huge responsibility. Encouraged by the proven loyalty of the many customers, Mrs. Micheli, supported by the loyal employees, decided to continue the business after all.",
          "Mimac - Micheli Maschinen heute": "Mimac - Micheli machines today",
          "Dass dies die richtige Entscheidung war": "The results and development of Micheli Machines prove that this was the right decision. Today, Micheli Maschinen is a leader in the marketing of woodworking machinery and equipment far beyond the borders of South Tyrol. Expert employees, representatives and technical advisors, led by Mrs. Micheli with great support from her son Arno, offer the largest selection of high-tech equipment for demanding craftsmen, but also for skilled DIY enthusiasts.",

          "Maschinen": "machinery",
          "Kleinmaschinen": "Small machines",
          "Ersatzteile": "Spare Parts",
          "Kontakt": "Contact",
          "Suche...": "Search...",

          "Neumaschinen": "New machines",
          "Gebrauchtmaschinen": "Used machines",
          "Ersatzteile": "Spare Parts",
          "Service - Reparaturen": "Service - repairs",
          "Kleinmaschinen": "Small machines",
          "Sonstiges": "Miscellaneous",
          "Absauganlagen": "Extraction systems",
          "Über uns - Kontakt": "About us - Contact",


          "Kundenservice": "Customer service",
          "Service für Ersatzteile, Reparaturen, Schulungen, Planungen, Schleifdienst und vieles mehr.": "Service for spare parts, repairs, training, planning, grinding service and much more.",
          "Reparaturen": "Repairs",
          "Wir kümmern uns um Sie auch nach dem Verkauf!": "We take care of you even after the sale!",
          "Kurse und Schulungen": "Courses and training",
          "Damit Ihre Mitarbeiter immer auf dem neuesten Stand sind.": "So that your employees are always up to date.",
          "Projektplanungen": "Project planning",
          "Planen Sie in Ihrer Werkstatt etwas zu ändern? Wir unterstützen Sie von der Idee bis zur Umsetzung.": "Are you planning to change something in your workshop? ",
          "Lieferservice": "Delivery service",
          "Wir bieten einen schnellen Lieferservice.": "We offer a fast delivery service.",
          "Kompetente Beratungen": "Competent advice",
          "Kompetente Beratung seit über 50 Jahren.": "Competent advice for over 50 years.",
          "Wir bieten": "We offer",

          "Marken": "Brands",
          "Wir bieten eine große Auswahl an renomierten Maschinen-Marken für die Holzverarbeitung an.": "We offer a large selection of renowned machine brands for wood processing.",

          "Informationen": "Contact information",
          "Montag - Donnerstag": "Monday - Thursday",
          "Freitag": "Friday",
          "Addresse": "Address",
          "Schlachthofstrasse 91 \n 39100 - Bozen (Südtirol) ": "Via del Macello, 91, 39100 Bolzano BZ",
          "Öffnungszeiten": "Opening time",
          "Telefon": "Telephone",
          "Impressum": "Imprint",
          "E-Mail": "E-mail",

          "Ersatzteile": "Spare Parts",
          "Wir haben ein großes Ersatzteillager für Maschinen jeglicher Art": "We have a large spare parts warehouse for all types of machines",
          "Kontaktieren Sie uns": "Contact us",
          "Ersatzteil Anfrage": "Spare part request",
          "Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot.": "I am interested in spare parts:\n ",
          "E-Mail Ersatzteil-Angebot anfragen": "Request a spare parts offer by email",
          "WhatsApp Ersatzteil-Angebot anfragen": "Request a spare parts offer via WhatsApp",

          "Art.-Nr.": "Item No.",
          "Hersteller": "Manufacturer",
          "Modell": "Model",
          "Kategorie": "category",
          "Gebrauchte Maschine": "Used machine",
          "Neue Maschine": "New machine",
          "Artikelnummer": "item number",
          "Lieferstatus": "Delivery status",
          "Wähle eine Variante": "Choose a variant",
          "inkl. gesetzl. MwSt. 22%": "including legal ",
          "Dokumente": "Documents",
          "Angebots Anfrage: ": "Quotation request: ",
          "Ich habe interesse an: ": "I'm interested in: ",
          "Ich bitte um ein Angebot.": "I ask for an offer.",
          "E-Mail Angebot anfragen": "Request an offer by email",
          "WhatsApp Angebot anfragen": "Request a WhatsApp offer",
          "Zubehör auf Anfrage": "Accessories on request",
          "Ähnliche Produkte": "similar products",
          "Andere Produkte von ": "Other products from ",
          "Mit folgenden Optionals": "With the following optionals",
          "Lagernd": "In stock",
          "Nicht lagernd": "Out of Stock",
          "Mehr": "More",

          "Alle Kategorien": "All Categories",
          "Alle Hersteller": "All manufacturers",
          "Gebrauchte Maschinen": "Used machines",
          "Neue Maschinen": "New machines",
          "Alle Kleinmaschinen": "All small machines",
          "Alle Maschinen": "All machines",
          "Neue Kleinmaschinen": "New small machines",
          "Gebrauchte Kleinmaschinen": "Used small machines",
          "Sortieren": "Sort by",
          "Neuheiten": "New hits",
          "Beliebt": "Popular",
          "Angebote": "Offers",
          "Hersteller": "Manufacturer",
          "Kategorie": "Category",
          "Keine Kleinmaschinen gefunden": "No small machines found",
          "Keine Maschinen gefunden": "No machines found",
          "Keine Produkte gefunden": "No products found",

          "Top Werkzeug": "Top tools",
          "Beliebte Maschinen": "Popular machines",
          "Beliebte Produkte": "Popular products",

          //Popup
          "Nicht mehr anzeigen": "Don't show anymore",

        }
      },
      it: {
        translation: {
          "Maschinen im Angebot": "Macchine in offerta",
          "Es gab noch nie": "Non c'è mai stato un momento migliore per sostituire quello vecchio con uno nuovo",
          "Beliebtesten Maschinen": "Macchine più popolari",
          "Wir bieten eine": "Offriamo una vasta scelta di macchine nuove e usate",
          "Neusten Maschinen": "Macchine più recenti",
          "Maschinen frisch aus der Fabrik!": "Macchine fresche di fabbrica!",
          "Cookie MSG": "Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.",
          "Werkzeug im Angebot": "Strumento in offerta",
          "Es gab noch nie so einen guten Moment jetzt zuzugreifen": "Non c'è mai stato un momento migliore per afferrarlo adesso",
          "Topseller Werkzeug": "Strumento più venduto",
          "Mit diesem Werkzeug kann man nicht viel falsch machen": "Non puoi sbagliare con questo strumento",
          "Neustes Werkzeug": "Strumento più recente",
          "Nigelnagelneues Werkzeug direkt von der Werkbank": "Nuovi strumenti direttamente dal banco di lavoro",
          "Mimac - Holzverarbeitungsmachinen, Kantenanleimmaschinen, Langbandschleifmaschinen in Südtirol Italien": "Mimac - macchine per la lavorazione del legno, bordatrici, levigatrici a nastro lungo in Alto Adige Italia",

          "Unsere Firmengeschichte": "La nostra storia aziendale",
          "1958 beschloss Albert Micheli": "Nel 1958, dopo aver completato l'apprendistato e aver acquisito una certa esperienza nella vendita di macchine per il commercio e l'industria, Albert Micheli decise di prendere in mano la situazione e di fondare la propria azienda. In Rittner Straße a Bolzano viene aperto un piccolo negozio per la commercializzazione di macchine e attrezzature di alta qualità per la lavorazione del legno, che nel 1966 viene ampliato in Andreas-Hofer-Straße. Nel 1973 è seguito il trasferimento in Schlachthofstrasse 91-93, dove ancora oggi si trova la sede centrale dell'azienda. Allo stesso tempo, il giovane imprenditore, attivo anche nella famiglia Kolping, e alcuni amici, tra cui l'esperto di lavorazione del legno Karl Pichler, si impegnarono per la formazione dei giovani nel settore del legno e sostennero l'istituzione dell'istituto tecnico per la lavorazione meccanica del legno a Merano. Albert Micheli, personalità estremamente accessibile e affascinante, era già innovativo negli anni '60 e '70 e costruì l'immagine della sua azienda sulla fedeltà personale dei clienti. La Micheli Maschinen divenne ben presto sinonimo di scelta, consulenza esperta, massima qualità e servizio affidabile per tutti gli artigiani che operano nel settore del legno.",
          "Ein Schicksalsschlag": "Un colpo del destino",
          "Doch genau im Jahr des 30.": "Ma nel 1988, anno del 30° anniversario dell'azienda, lo sportivo Albert Micheli muore improvvisamente di infarto. I suoi 3 figli erano ancora in età scolare. L'enorme vuoto che aveva lasciato doveva essere colmato dalla moglie. Era in grado di farlo? Aveva sempre lavorato al fianco del marito nell'azienda, ma assumere il ruolo di leader era una responsabilità enorme. Incoraggiata dalla comprovata fedeltà dei numerosi clienti, la signora Micheli, sostenuta dai suoi fedeli dipendenti, decise di continuare l'attività.",
          "Mimac - Micheli Maschinen heute": "Le macchine Mimac - Micheli oggi",
          "Dass dies die richtige Entscheidung war": "I risultati e lo sviluppo della Micheli Macchine dimostrano che si trattava della decisione giusta. Oggi la Micheli Macchine è leader nella commercializzazione di macchine e attrezzature per la lavorazione del legno ben oltre i confini dell'Alto Adige. Dipendenti esperti, rappresentanti e consulenti tecnici, guidati dalla signora Micheli con il grande supporto del figlio Arno, offrono la più ampia selezione di attrezzature high-tech per gli artigiani più esigenti, ma anche per gli abili appassionati del fai da te.",

          "Maschinen": "macchinari",
          "Kleinmaschinen": "Piccole macchine",
          "Ersatzteile": "Pezzi di ricambio",
          "Kontakt": "Contatto",
          "Suche...": "Cerca...",

          "Neumaschinen": "Nuove macchine",
          "Gebrauchtmaschinen": "Macchine usate",
          "Ersatzteile": "Pezzi di ricambio",
          "Service - Reparaturen": "Assistenza - riparazioni",
          "Kleinmaschinen": "Piccole macchine",
          "Sonstiges": "Varie cose",
          "Absauganlagen": "Sistemi di estrazione",
          "Über uns - Kontakt": "Chi siamo - Contatto",

          "Kundenservice": "Assistenza clienti",
          "Service für Ersatzteile, Reparaturen, Schulungen, Planungen, Schleifdienst und vieles mehr.": "Servizio per pezzi di ricambio, riparazioni, formazione, progettazione, servizio di rettifica e molto altro.",
          "Reparaturen": "Riparazioni",
          "Wir kümmern uns um Sie auch nach dem Verkauf!": "Ci prendiamo cura di te anche dopo la vendita!",
          "Kurse und Schulungen": "Corsi e formazione",
          "Damit Ihre Mitarbeiter immer auf dem neuesten Stand sind.": "In modo che i tuoi dipendenti siano sempre aggiornati.",
          "Projektplanungen": "Pianificazione del progetto",
          "Planen Sie in Ihrer Werkstatt etwas zu ändern? Wir unterstützen Sie von der Idee bis zur Umsetzung.": "Hai intenzione di cambiare qualcosa nel tuo laboratorio? ",
          "Lieferservice": "Servizio di consegna",
          "Wir bieten einen schnellen Lieferservice.": "Offriamo un servizio di consegna veloce.",
          "Kompetente Beratungen": "Consulenza competente",
          "Kompetente Beratung seit über 50 Jahren.": "Consulenza competente da oltre 50 anni.",
          "Wir bieten": "Noi offriamo",

          "Marken": "Marche",
          "Wir bieten eine große Auswahl an renomierten Maschinen-Marken für die Holzverarbeitung an.": "Offriamo una vasta scelta di rinomati marchi di macchine per la lavorazione del legno.",

          "Informationen": "Informazioni di contatto",
          "Montag - Donnerstag": "Lunedì - Giovedì",
          "Freitag": "Venerdì",
          "Addresse": "Indirizzo",
          "Schlachthofstrasse 91 \n 39100 - Bozen (Südtirol) ": "Via del Macello, 91, 39100 Bolzano BZ",
          "Öffnungszeiten": "Orari di apertura",
          "Telefon": "Telefono",
          "Impressum": "Impronta",
          "E-Mail": "E-mail",

          "Ersatzteile": "Pezzi di ricambio",
          "Wir haben ein großes Ersatzteillager für Maschinen jeglicher Art": "Disponiamo di un ampio magazzino ricambi per tutte le tipologie di macchine",
          "Kontaktieren Sie uns": "Contattaci",
          "Ersatzteil Anfrage": "Richiesta pezzo di ricambio",
          "Ich habe Interesse an Ersatzteil: \n Quantität: \n Marke: \n Seriennummer: \n Ich bitte um ein Angebot.": "Sono interessato ai pezzi di ricambio:\n ",
          "E-Mail Ersatzteil-Angebot anfragen": "Richiedi un'offerta di pezzi di ricambio via e-mail",
          "WhatsApp Ersatzteil-Angebot anfragen": "Richiedi un'offerta di pezzi di ricambio tramite WhatsApp",

          "Art.-Nr.": "Oggetto numero.",
          "Hersteller": "Produttore",
          "Modell": "Modello",
          "Kategorie": "categoria",
          "Gebrauchte Maschine": "Macchina usata",
          "Neue Maschine": "Nuova macchina",
          "Artikelnummer": "codice articolo",
          "Lieferstatus": "Stato della consegna",
          "Wähle eine Variante": "Scegli una variante",
          "inkl. gesetzl. MwSt. 22%": "anche legale ",
          "Dokumente": "Documenti",
          "Angebots Anfrage: ": "Richiesta di preventivo: ",
          "Ich habe interesse an: ": "Sono interessato a: ",
          "Ich bitte um ein Angebot.": "Chiedo un'offerta.",
          "E-Mail Angebot anfragen": "Richiedi un'offerta tramite e-mail",
          "WhatsApp Angebot anfragen": "Richiedi un'offerta WhatsApp",
          "Zubehör auf Anfrage": "Accessori su richiesta",
          "Ähnliche Produkte": "Prodotti Simili",
          "Andere Produkte von ": "Altri prodotti da ",
          "Mit folgenden Optionals": "Con i seguenti optional",
          "Lagernd": "In magazzino",
          "Nicht lagernd": "Esaurito",
          "Mehr": "Di più",

          "Alle Kategorien": "Tutte le categorie",
          "Alle Hersteller": "Tutti i produttori",
          "Gebrauchte Maschinen": "Macchine usate",
          "Neue Maschinen": "Nuove macchine",
          "Alle Kleinmaschinen": "Tutte le piccole macchine",
          "Alle Maschinen": "Tutte le macchine",
          "Neue Kleinmaschinen": "Nuove piccole macchine",
          "Gebrauchte Kleinmaschinen": "Piccole macchine usate",
          "Sortieren": "Ordina",
          "Neuheiten": "Nuovi successi",
          "Beliebt": "Popolare",
          "Angebote": "Offerte",
          "Hersteller": "Produttore",
          "Kategorie": "Categoria",
          "Keine Kleinmaschinen gefunden": "Nessuna macchina piccola trovata",
          "Keine Maschinen gefunden": "Nessuna macchina trovata",
          "Keine Produkte gefunden": "Nessun prodotto trovato",

          "Top Werkzeug": "I migliori strumenti",
          "Beliebte Maschinen": "Macchine popolari",
          "Beliebte Produkte": "Prodotti popolari",

          //Popup
          "Nicht mehr anzeigen": "Non mostrare più",
        }
      }
    }
  })