import React, { useState, useEffect } from 'react';

export function Impressum() {
    // {/* </tr> <td><span id="e552924708"><a href="mailto:home@mimac.it">home@mimac.it</a></span><script type="text/javascript">/*<![CDATA[*/eval("var a=\"cydeNDx9RAw.02bXCvKtY8MO3l1h_5LBQTpG6Vimqrn4E7UjoaF+@zgJPu-SHkZIfsW\";var b=a.split(\"\").sort().join(\"\");var c=\"oJz72zazr4daH\";var d=\"\";for(var e=0;e<c.length; e++)d+=b.charAt(a.indexOf(c.charAt(e)));document.getElementById(\"e552924708\").innerHTML=\"<a href=\\\"mailto:\"+d+\"\\\">\"+d+\"</a>\"")/*]]>*/</script></td> */}
    return (

        <div className="mt-7 margintp" >
            <div className="container">
                <div className="mb-4">
                    <h3>Impressum</h3>

                    <table className="simple-table" >
                        {/* cellspacing="0" cellpadding="0" border="0" */}
                        <tbody>
                            <tr>
                                <td>
                                    Firma
                                </td>
                                <td>
                                    Mimac GmbH
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Anschrift
                                </td>
                                <td>
                                    Schlachthofstraße 91
                                </td>
                            </tr>
                            <tr><td>
                                PLZ / Ort
                            </td>
                                <td>
                                    I - 39100  &nbsp;Bozen/Bolzano (BZ)
                                </td>
                            </tr>
                            <tr><td>
                                Land
                            </td>
                                <td>
                                    Südtirol / Italien
                                </td>
                            </tr>
                            <tr><td>
                                Telefon
                            </td>
                                <td>
                                    +39&nbsp;0471 971561
                                </td>
                            </tr>
                            <tr><td>
                                Internet
                            </td>
                                <td><a href="https://www.mimac.it/">www.mimac.it</a></td>
                            </tr>
                            <tr><td>
                                E-Mail
                            </td>
                                <td>
                                <a href= "mailto:home@mimac.it">home@mimac.it</a>
                                </td>
                            </tr>
                            <tr><td>
                                MwSt.-Nummer
                            </td>
                                <td><span>IT00608410213 &nbsp;</span></td>
                            </tr>
                            <tr>
                                <td>Codice univoco </td>
                                <td>M5UXCR1</td>
                            </tr>
                            <tr><td>
                                Bildmaterial
                            </td>
                                <td>Shutterstock, Mimac</td>
                            </tr>
                            <tr><td>
                                Haftungshinweis
                            </td>
                                <td>
                                    Die auf dieser Website genannten Marken und Firmen sind Eigentum der jeweiligen Inhaber.<br /> <br /> Diese Website enthält Links auf Websites von Dritten. Trotz sorgfältiger inhaltlicher Kontrolle wird keinerlei Haftung für den Inhalt der verlinkten Websites übernommen. Die Verantwortung und Haftung für deren Inhalt und Gestaltung liegt ausschließlich bei den jeweiligen Betreibern.
                                    &nbsp;
                                </td>
                            </tr>
                            <tr><td>
                                Google Analytics
                            </td>
                                <td>
                                    Diese Website benutzt Google Analytics und Google Remarketing, einen Webanalysedienst der Google Inc. („Google"). Google Analytics verwendet sog. „Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.
                                    &nbsp;
                                    Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können.
                                    &nbsp;
                                    Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Datenschutz
                                </td>
                                <td>
                                    Im Sinne des Art. 13 des Gesetzesvertretenden Dekrets Nr. 196 vom 30. Juni 2003 und nachfolgende Änderungen und Ergänzungen informieren wir darüber, dass alle Daten, welche Sie über unseren Internetauftritt bzw. über andere Wege (Fax, e-Mail, Post) an uns senden, gesammelt und gespeichert werden. Wir garantieren im Rahmen der gesetzlichen Vorgaben, dass die Verarbeitung der persönlichen Daten unter Berücksichtigung der grundlegenden Rechte und Freiheiten sowie der Würde des Betroffenen mit besonderem Bezug auf die Geheimhaltung, die persönliche Identität und das Recht auf Schutz der persönlichen Daten erfolgt.
                                    &nbsp;
                                    Die von uns verarbeiteten persönlichen Daten unterliegen keiner Verbreitung.
                                    &nbsp;
                                    Das Datenschutzgesetz verleiht den Betroffen gemäß Art. 7 das Recht seine Daten zu bestätigen und zu kontrollieren, zu berichtigen und zu ergänzen, zu beantragen, dass die Daten gelöscht, gesperrt und in anonyme Daten umgewandelt werden, falls die Verarbeitung gegen die gesetzlichen Bestimmungen verstößt. Er hat das Recht sich aus gerechtfertigtem Grund gänzlich oder zum Teil der Verarbeitung seiner Daten zu widersetzen, sowie ihre Löschung, Sperrung, Umwandlung in anonyme Daten zu verlangen und ohne gerechtfertigtem Grund, wenn Daten zum Zwecke der Handelsinformation, des Versands von Werbematerial, des Direktverkaufs, zu Markt- und Meinungsforschung verwendet werden.
                                    &nbsp;<br />
                                    &nbsp;<br />
                                    <strong>Inhaber der Datenverarbeitung ist:</strong>
                                    {'  '}Mimac GmbH,  Schlachthofstraße 91, I - 39100  &nbsp;Bozen/Bolzano (BZ), Südtirol / Italien, Tel. +39 0471 971561
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}