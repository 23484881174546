import SearchField from "react-search-field";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'

export function Search(props) {
    const { t, i18n } = useTranslation()
    return (
        <SearchField
            placeholder={t("Suche...")}
            searchText={props.suchtext}
            onEnter={(v,e) => props.onEnter(v)}
            onSearchClick={(v,e) => props.onEnter(v)}
            classNames="searchfield-class"
        />
    )
}