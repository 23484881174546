import React, { useState, useEffect } from 'react';
// import { Carousel } from 'react-carousel-minimal';
import { useParams, useRouteMatch, useHistory, Link } from "react-router-dom";
import { MaschinenSlider } from './MaschinenSlider';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
// import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next'

export const Unterseiten = {
  Kleinmaschinen: "Kleinmaschinen",
  Sonstiges: "Sonstiges",
  Absauganlagen: "Absauganlagen",
  Maschinen: "Maschinen",
}

export function MaschinenDetails(props) {
  const { t } = useTranslation()
  let { mid } = useParams();
  let history = useHistory();
  const [maschine, setMaschine] = useState({});
  const [images, setImages] = useState([]);
  const [variante, setVariante] = useState("")
  const [checkedState, setCheckedState] = useState(null);

  function handleClick() {
    history.goBack();
  }

  function fetchImages(maschine) {
    if (!(Object.keys(maschine).length === 0 && maschine.constructor === Object)) {
      setImages(maschine["Fotos_full"].map(e => { return { "src": e } }))
    }
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };


  useEffect(() => { let temp = props.maschinen.filter(m => m.id === mid); console.log("temp", temp); if (temp.length > 0) { setMaschine(temp[0]); } }, [mid, props.maschinen])
  useEffect(() => { fetchImages(maschine); document.title = "Mimac - " + maschine["Bezeichnung"]; console.log(maschine) }, [maschine])
  useEffect(() => { if (maschine["varianten"]) { setVariante(maschine["varianten"][0]) } }, [maschine])
  useEffect(() => { if (maschine["Optionals"]) { setCheckedState(new Array(maschine["Optionals"].length).fill(false)); console.log("Optionals to false") } }, [maschine])



  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  const Checkbox = ({ label }) => {
    return (
      <div className='checkboxoptionals'>
        <input type="checkbox" id={label.replace(/\s+/g, '')} value={label} />
        <label htmlFor={label.replace(/\s+/g, '')}>
          {label}
        </label>
      </div>
    );
  };
  return (
    <React.Fragment>
      {/* <Helmet>
        { maschine["Fotos_full"] && <meta property="og:image" content={maschine["Fotos_full"][0]} /> }
      </Helmet> */}
      {/* <button type="button" className="btn btn btn-outline-secondary" onClick={e => handleClick()}>&larr;</button> */}
      <h3 style={{ fontSize: "28px" }} className='maschinen-bezeichnung'>{maschine["Bezeichnung"]}</h3>
      {maschine["artikelnummer"] && <p>{t("Artikelnummer")} {maschine["artikelnummer"]}</p>}
      <div className="row">
        {/* <div className="col">
          <img className="" src={"https://mimac.it/content/uploads/images/" + props.i.thumb} height="225" />
          </div> */}
        <div className="col-md-8 col-lg-9" > {/*style={{ border: "1px solid red" }}*/}
          {
            images.length > 0 &&
            <Carousel
              images={images}
              style={{ height: 700, backgroundColor: "white" }}
              shouldMaximizeOnClick={true}
              shouldMinimizeOnClick={true}
              hasIndexBoard={false}
              hasMediaButton={false}
              objectFit="contain"
            />

          }
        </div>
        <div className="col-md-4 col-lg-3">
          <ul>
            {maschine["manufacturer_name"] &&
              <li>
                <span className="fa fa-check-circle-o brown-marker" ></span><b>{t("Hersteller")}:</b> {maschine.manufacturer_name}
              </li>}
            {maschine["modell"] &&
              <li>
                <span className="fa fa-check-circle-o brown-marker" ></span><b>{t("Modell")}:</b> {maschine.modell}
              </li>}
            {maschine["cat_name"] &&
              <li>
                <span className="fa fa-check-circle-o brown-marker" ></span><b>{t("Kategorie")}:</b> {maschine.cat_name}
              </li>}
            {maschine["type"] &&
              <li>
                <b>
                  {maschine.unterseite === Unterseiten.Maschinen && <React.Fragment><span className="fa fa-check-circle-o brown-marker" ></span>{maschine["Zustand"] && maschine["Zustand"] === 'Gebraucht' ? t("Gebrauchte Maschine") : t("Neue Maschine")}</React.Fragment>}
                </b>
              </li>}
            {maschine["artikelnummer"] &&
              <li>
                <span className="fa fa-check-circle-o brown-marker" ></span><b>{t("Artikelnummer")}:</b> {maschine["artikelnummer"]}
              </li>}
            {maschine["Lieferstatus"] &&
              <li>
                <span className="fa fa-check-circle-o brown-marker" ></span><b>{t("Lieferstatus")}:</b> {t(maschine["Lieferstatus"])} <span className={maschine["Lieferstatus"] === "Lagernd" ? "green-dot" : "red-dot"}>●</span>
              </li>}
            {maschine["varianten"] &&
              <li>
                <span style={{ "display": "inline-block" }}>
                  <b>{t("Wähle eine Variante")}:</b>
                </span>
              </li>
            }
            {maschine["varianten"] &&
              <li>
                <div style={{ "textAlign": "right" }}>
                  <select name="varianten" id="varianten" value={variante} onChange={(e) => setVariante(e.target.value)} style={{ "width": "100%", "text-align": "center" }}>
                    {/* setVariant(choice) */}
                    {maschine["varianten"].map(i => <option value={i} key={i}>{i}</option>)}
                  </select>
                </div>
              </li>
            }
            {maschine.Verkaufspreis &&
              <li className='preis'>
                {<h2 className={maschine.angebot_aktiv ? "alter-preis" : "verkaufspreis"}>{maschine["Verkaufspreis"]}€</h2>}
                {maschine.angebot_aktiv && <h2 className='verkaufspreis'>{maschine["Angebotspreis"]}€</h2>}
                <span>{t("inkl. gesetzl. MwSt. 22%")}</span>
              </li>}


          </ul>
          <div className='kurzbeschreibung' dangerouslySetInnerHTML={{ __html: maschine.description }} />
          {maschine["Dokumente"] && maschine["Dokumente"].length > 0 && <div className="row" style={{ marginTop: "100px" }}>
            <b>{t("Dokumente")}</b>
            <ul>
              {maschine["Dokumente"].map(i => <DownloadLink i={i} key={i.title + "dl"}></DownloadLink>)}
            </ul>
          </div>}
          {/* href={"mailto: home@mimac.it?subject=" + encodeURI("Angebots anfrage: " + maschine.name) + "&body=" + encodeURIComponent("Ich habe interesse an: " + maschine["Bezeichnung"] + ". Ich bitte um ein Angebot.")} */}

          <div className='row'>
            <a className='anfrage-link' href={"mailto:" + (maschine.unterseite !== Unterseiten.Maschinen ? "geschaeft@mimac.it" : "organisation@mimac.it") + "?subject=" + encodeURI(t("Angebots Anfrage: ") + maschine["Bezeichnung"]) + "&body=" + encodeURIComponent(t("Ich habe interesse an: ") + maschine["Bezeichnung"] + "\n"+ "ID: " + mid + "\n" + varianteText() + "\n" + optionalsText() + t("Ich bitte um ein Angebot."))} onClick={() => anfrageSchicken()}>
              <div className='anfrage-button' style={{ width: "100%" }}>
                <i className='fa fa-envelope-o'></i> {t("E-Mail Angebot anfragen")}
              </div>
            </a>


            <a className='anfrage-link' href={"https://wa.me/" + (maschine.unterseite !== Unterseiten.Maschinen ? "00390471971561" : "00393356770348") + "?text=" + encodeURI(t("Ich habe interesse an: ") + maschine["Bezeichnung"] + "\n" + varianteText() + "\n" + "ID: " + mid + "\n" + optionalsText() + t("Ich bitte um ein Angebot."))} target="_blank" rel="noopener noreferrer">
              <div className='anfrage-button' style={{ width: "100%" }}>
                <i className='fa fa-whatsapp'></i> {t("WhatsApp Angebot anfragen")}
              </div>
            </a>
          </div>
        </div>
      </div>
      {
        maschine["youtube-video"] && maschine["youtube-video"].length > 0 && <iframe width="560" height="315" className='embeddedvideo' src={"https://www.youtube-nocookie.com/embed/" + maschine["youtube-video"]} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      }

      {/* {maschine["Optionals"] &&
        <div className="row" style={{ marginTop: "100px" }}>
          <h3>Zubehör auf Anfrage</h3>
          {maschine["Optionals"].map((i, index) => <Checkbox label={i} value={i} key={i} checked={checkedState[index]} onChange={() => handleOnChange(index)}></Checkbox>)}
        </div>
      } */}
      {maschine["Optionals"] &&
        <div className="row" style={{ marginTop: "100px" }}>
          <h3>{t("Zubehör auf Anfrage")}</h3>
          {maschine["Optionals"].map((i, index) =>
            <div key={index} className='checkboxoptionals'>
              <label className="checkboxoptionals" htmlFor={`custom-checkbox-${index}`}>
                <input
                  className='checkboxoptionals'
                  id={`custom-checkbox-${index}`}
                  type="checkbox"
                  name={i}
                  value={i}
                  checked={checkedState ? checkedState[index] : false}
                  onChange={() => handleOnChange(index)}></input>
                {i}
              </label>
            </div>
          )}
        </div>
      }






      <div className="row " style={{ marginTop: "100px" }}>
        <div className='beschreibung' dangerouslySetInnerHTML={{ __html: maschine["Beschreibung"] }} />
      </div>


      {maschine["Dokumente"] && maschine["Dokumente"].length > 0 && <div className="row" style={{ marginTop: "100px" }}>
        <h3>{t("Dokumente")}</h3>
        <ul>
          {maschine["Dokumente"].map(i => <DownloadLink i={i} key={i.title + "dl"}></DownloadLink>)}
        </ul>
      </div>}
      <div className="row" style={{ marginTop: "100px" }}>
      </div>
      {MaschinenSliderComp("cat_name", t("Ähnliche Produkte"), "?kategorie=")}
      {MaschinenSliderComp("manufacturer_name", t("Andere Produkte von ") + maschine["Hersteller"], "?hersteller=")}
      {MaschinenSliderCompTop()}
    </React.Fragment >
  );

  function anfrageSchicken() {
    // console.log("mailto: home@mimac.it?subject=" + encodeURI("Angebots anfrage: " + maschine.name) + "&body=" + encodeURIComponent("Ich habe interesse an: " + maschine["Bezeichnung"] + ". Ich bitte um ein Angebot."))
  }

  function varianteText() {
    if (variante.length > 0) {
      return "Variante: " + variante + "\n";
    }
    return "";
  }

  function optionalsText() {
    if (maschine && maschine["Optionals"] && maschine["Optionals"].length > 0 && checkedState) {
      const text = maschine["Optionals"].filter((i, index) => checkedState[index]).reduce((acc, curr) => acc + "\n" + curr, "")
      if (text.length < 1) {
        return ""
      }
      console.log(text);
      return t("Mit folgenden Optionals") + ": \n" + text + "\n\n";
    }
    return "";
  }

  function DownloadLink(props) {
    return <li>
      <a className="kiss-cover" href={props.i.path} target="_blank" rel="noopener"><span className="fa fa-file-text-o brown-marker"></span>{props.i.title}</a>
    </li>

  }

  function MaschinenSliderCompTop() {
    let maschinen = [...(props.maschinen).filter(i => i["top_maschine"])].sort((a, b) => b["top_maschine"] - a["top_maschine"]).slice(0, 10);
    if (maschinen.length > 0) {
      return <div className='mb-5'>
        <div className=' container section-title text-center' style={{ paddingTop: "50px", marginBottom: "20px" }}>
          <h2 style={{ fontSize: "20px" }}>{maschine.unterseite === Unterseiten.Kleinmaschinen ? t("Top Werkzeug") : (maschine.unterseite === Unterseiten.Maschinen ? t("Beliebte Maschinen") : t("Beliebte Produkte"))} </h2>
        </div>
        <MaschinenSlider maschinen={maschinen} mehrLink={props.path + "?sort=top_maschine"} path={props.path}/>
      </div>;
    }
    return null;
  }

  function MaschinenSliderComp(propertyName, h2text, mehrLink) {
    let filteredMaschinen = props.maschinen.filter(m => m[propertyName] === maschine[propertyName] && m.id !== mid);
    if (filteredMaschinen.length > 0) {
      return <div className='mb-5'>
        <div className=' container section-title text-center' style={{ paddingTop: "50px", marginBottom: "20px" }}>
          <h2 style={{ fontSize: "20px" }}>{h2text}</h2>
        </div>
        <MaschinenSlider maschinen={filteredMaschinen} mehrLink={props.path + mehrLink + maschine[propertyName]} path={props.path} />
      </div>;
    }
    else return null;
  }

  function searchParamString(herstellerP, kategorieP, gebrauchtP, suchtextP, sortTextP) {
    const params = new URLSearchParams({
      hersteller: herstellerP,
      kategorie: kategorieP,
      zustand: gebrauchtP,
    });
    return params.toString()
  }
}

