import { useParams, useRouteMatch, useHistory, Link } from "react-router-dom";
import { Search } from "./Search";
import React  from 'react';
import { useTranslation } from 'react-i18next'

export function Navigation2(props) {
    const { t, i18n } = useTranslation()

    let history = useHistory();
    function handleChange(event) {
        props.s(event.target.value.toLowerCase());
    }
    function onEnter(x){
        console.log("onEnter", x)
        history.push({pathname: "/suche", search:`?suchtext=${x}` })
        // history.push({pathname: `/werkzeug?kategorie=Flex` })
    }
    return (
        <nav className="navbar sticky-top navbar-expand-md navbar-light bg-white" aria-label="Seventh navbar example" style={{ boxShadow: "0 3px 4px 0px rgba(0,0,0,.2)" }} >
            <div className="container-fluid" >
                <Link className='navbar-brand nav-link' to="/">
                    <img src="img/logo.png" alt="" style={{ width: "150px", height: "57px" }} />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExampleXxl" aria-controls="navbarsExampleXxl" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExampleXxl">
                    <ul className="navbar-nav me-auto mb-2 mb-xl-0">
                        <li className="nav-item">
                            <Link to='/maschinen' className='nav-link'> {t("Maschinen")} </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/werkzeug' className='nav-link'> {t("Kleinmaschinen")} </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to='/projekte' className='nav-link'> Projekte </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to='/ersatzteile' className='nav-link'> {t("Ersatzteile")} </Link>
                        </li>
                        {/* <li className="nav-item">
                            <a href={base+'/#about'} className='nav-link'>
                                Über uns
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link to='/about' className='nav-link'>
                                Unsere Geschichte
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href={base+'/#team'} className='nav-link'>
                                Team
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a href={'/#contact'} className='nav-link'>
                                {t("Kontakt")}
                            </a>
                        </li>
                    </ul>

                    <a href="tel:+39 0471 971561" style={{textDecoration: "none", color: "brown", marginRight: "20px"}}>
                        <button className="nav-tel-button">
                            <i className='fa fa-phone'></i> Mimac +39 0471 971561
                        </button>{' '}
                    </a>
                    {/* <form action="">
                        <input className="form-control" type="text" placeholder="Search" aria-label="Search" />
                    </form> */}
                    <Search searchText={props.searchText} setSearchText={props.setSearchText} onEnter={onEnter} />
                    <select className="dropdown-toggle lang-dropdown" value={i18n.resolvedLanguage} onChange={e => i18n.changeLanguage(e.target.value)} style={{ margin: "15px" }}>
                        <option className="dropdown-item" value="de">DE</option>
                        <option className="dropdown-item" value="it">IT</option>
                        <option className="dropdown-item" value="en">EN</option>
                    </select>
                </div>
            </div>
        </nav>
    )
}