import React, { useState, useEffect } from 'react'
import { Services } from './components/services'
import { Gallery } from './components/gallery'
import { Contact } from './components/contact'
import { Impressum } from './components/impressum'
import JsonDataDE from './data/data.de.json'
import SmoothScroll from 'smooth-scroll'
import { Maschinen } from './components/Maschinen'
import { AboutUs } from './components/aboutus'
import { MaschinenSlider } from './components/MaschinenSlider'
import { Projekte } from './components/Projekte'
import { Navigation2 } from './components/navigation2'
import { Ersatzteile } from './components/Ersatzteile'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { GridMenu } from './components/GridMenu'
import { SearchResults } from './components/SearchResults'
import ScrollToTop from './components/ScrollToTop'
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";
import { useTranslation } from 'react-i18next'
import { kategorienReset, herstellerReset } from './components/MaschinenGrid'
import { Unterseiten } from './components/MaschinenDetails'
import { Popup } from './components/popup'


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const { t, i18n } = useTranslation()
  const [landingPageData, setLandingPageData] = useState(JsonDataDE);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [maschinen, setMaschinen] = useState([]);
  const [categories, setCategories] = useState([]);
  const [marken, setMarken] = useState([]);
  const [popup, setPopup] = useState([]);
  const [produkte, setProdukte] = useState({ "Kleinmaschinen": { produkte: [], kategorien: [], hersteller: [] }, "Sonstiges": { produkte: [], kategorien: [], hersteller: [] }, "Absauganlagen": { produkte: [], kategorien: [], hersteller: [] } });


  const API = "https://mimac.it/cockpit-core/api/content/items/"

  function mapNewMaschine(n) {

    n["id"] = n["_id"]
    n["modell"] = n["Modell"]
    n["description"] = n["Kurzbeschreibung"]
    n["cat_name"] = n["Kategorie"]

    if (!("cat_name" in n)) {
      n["cat_name"] = ""
    }
    if (n["cat_name"] == null) {
      n["cat_name"] = ""
    }
    // console.log(n["Hersteller"].trimEnd())
    if (n["Hersteller"]) {
      n["Hersteller"] = n["Hersteller"].trimEnd();
    }

    n["type"] = n["Zustand"]
    n["marke"] = n["Hersteller"]
    if (!("marke" in n)) {
      n["marke"] = ""
    }
    if (n["marke"] == null) {
      n["marke"] = ""
    }
    n["manufacturer_name"] = n["Hersteller"]

    let x = n["Fotos"]
    const res = 255;
    const res2x = 400;
    const res3x = 600;
    if (Array.isArray(x) && x.length > 0) {
      n["thumb"] = "https://mimac.it/cockpit-core/api/assets/image/" + x[0]._id + "?m=thumbnail&h=" + res + "&q=80&o=1"
      n["thumb2x"] = "https://mimac.it/cockpit-core/api/assets/image/" + x[0]._id + "?m=thumbnail&h=" + res2x + "&q=60&o=1"
      n["thumb3x"] = "https://mimac.it/cockpit-core/api/assets/image/" + x[0]._id + "?m=thumbnail&h=" + res3x + "&q=60&o=1"
      n["Fotos_full"] = n["Fotos"].map((e) => "https://mimac.it/cockpit-core/storage/uploads/" + e.path)
    } else if (!Array.isArray(x) && x !== null) {
      n["thumb"] = "https://mimac.it/cockpit-core/api/assets/image/" + x._id + "?m=thumbnail&h=" + res + "&q=80&o=1"
      n["thumb2x"] = "https://mimac.it/cockpit-core/api/assets/image/" + x._id + "?m=thumbnail&h=" + res2x + "&q=60&o=1"
      n["thumb3x"] = "https://mimac.it/cockpit-core/api/assets/image/" + x[0]._id + "?m=thumbnail&h=" + res3x + "&q=60&o=1"
      n["Fotos_full"] = ["https://mimac.it/cockpit-core/storage/uploads/" + x.path]
    } else {
      console.log("Noppa");
      console.log(n);
      n["thumb"] = null;
      n["thumb2x"] = null;
      n["Fotos_full"] = [];
    }

    let dokumente = n["Dokumente"]
    if (dokumente && !Array.isArray(dokumente))
      n["Dokumente"] = [dokumente]
    if (n["Dokumente"]) {
      n["Dokumente"].map(i => i.path = "https://mimac.it/cockpit-core/storage/uploads/" + i.path)
    }

    if (!("angebot_prioritaet" in n)) {
      n["angebot_prioritaet"] = 0
    }
  }

  async function loadData2(baseUrl, unterseite) {
    console.log("trying to fetch ", baseUrl)
    return (await fetch(baseUrl)
      .then(res => res.json())
      .then(
        (result) => {
          result.forEach((e) => { mapNewMaschine(e); e.unterseite = unterseite });
          result.reverse();
          return result;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
      .then((result) => {
        console.log("loadData2");
        console.log(result)
        return result;
      }));
  };

  async function loadPopup(baseUrl) {
    return await fetch(baseUrl).then(res => res.json())
      .then((result) => {
        console.log("loadPopup");
        console.log(result)
        return result;
      }, (error) => {console.log(error)});
  }
  async function fetchPopup() {
    let popup = await loadPopup(API + "popup" + "?locale=" + i18n.resolvedLanguage);
    if (popup){
      setPopup(popup[0]);
    }
  }

  async function fetchWerkzeug() {
    async function fetchMergeAndExtract(subAPI, unterseite) {
      let defaultProducts = await loadData2(API + subAPI, unterseite);
      let localizedProducts = await loadData2(API + subAPI + "?locale=" + i18n.resolvedLanguage, unterseite);
      mergeDefaultWithLocalized(defaultProducts, localizedProducts);
      return extractKategorienAndHersteller(localizedProducts);
    }

    let getrennteProdukte = {};
    let unterseiten = [{ subAPI: "Werkzeug", unterseite: Unterseiten.Kleinmaschinen }, { subAPI: Unterseiten.Sonstiges, unterseite: Unterseiten.Sonstiges }, { subAPI: Unterseiten.Absauganlagen, unterseite: Unterseiten.Absauganlagen }];
    for (let index = 0; index < unterseiten.length; index++) {
      const { subAPI, unterseite } = unterseiten[index];
      console.log(subAPI, unterseite);
      let produkteKategorienHerstellerObj = await fetchMergeAndExtract(subAPI, unterseite);
      getrennteProdukte[unterseite] = produkteKategorienHerstellerObj;
      console.log("getrennte Produkte");
      console.log(getrennteProdukte);
    }
    setProdukte(getrennteProdukte);
  }

  async function fetchMaschinen() {
    let defaultProducts = await loadData2(API + "Maschinen", Unterseiten.Maschinen);
    let localizedProducts = await loadData2(API + "Maschinen" + "?locale=" + i18n.resolvedLanguage, Unterseiten.Maschinen);
    mergeDefaultWithLocalized(defaultProducts, localizedProducts);
    const { kategorien, hersteller } = extractKategorienAndHersteller(localizedProducts);
    localizedProducts.forEach(p => p.unterseite = Unterseiten.Maschinen);
    setCategories(kategorien);
    setMarken(hersteller);
    setMaschinen(localizedProducts)
  }

  function mergeDefaultWithLocalized(defaultProducts, localizedProducts) {
    localizedProducts.forEach((obj, index) =>
      Object.keys(obj).forEach(k => {
        if (obj[k] === null || (Array.isArray(obj[k]) && obj[k].length === 0)) {
          obj[k] = defaultProducts[index][k];
        }
      }));
  }

  function extractKategorienAndHersteller(productArray) {
    let cat = new Set();
    let mark = new Set();
    productArray.forEach(p => {
      cat.add(p.cat_name);
      mark.add(p["Hersteller"]);
    });
    let kategorienArr = Array.from(cat).sort();
    kategorienArr.unshift(kategorienReset);
    let herstellerArr = Array.from(mark).sort();
    herstellerArr.unshift(herstellerReset);

    return {
      produkte: productArray,
      kategorien: kategorienArr,
      hersteller: herstellerArr
    }
  }

  // useEffect(() => {
  //   fetchMaschinen();
  //   fetchWerkzeug();
  //   document.title = t("Mimac - Holzverarbeitungsmachinen, Kantenanleimmaschinen, Langbandschleifmaschinen in Südtirol Italien");
  // }, []);

  useEffect(() => {
    fetchMaschinen();
    fetchWerkzeug();
    fetchPopup();
    document.title = t("Mimac - Holzverarbeitungsmachinen, Kantenanleimmaschinen, Langbandschleifmaschinen in Südtirol Italien");
  }, [i18n.resolvedLanguage])

  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <div>
        {/* <Navigation searchText={searchText} setSearchText={setSearchText} language={language} setLanguage={setLanguage} /> */}
        <Navigation2 />
        <Popup p={popup}></Popup>
        <div style={{ marginTop: "20px" }}></div>
        <Switch>
          <Route path="/maschinen">
            <Maschinen maschinen={maschinen} categories={categories} marken={marken} path="/maschinen" unterseite={Unterseiten.Maschinen} />
          </Route>
          <Route path="/suche">
            <SearchResults produkte={produkte} maschinen={maschinen} categories={categories} marken={marken}></SearchResults>
            {/* <Maschinen searchText={searchText} setSearchText={setSearchText} maschinen={maschinen} categories={categories} marken={marken} /> */}
          </Route>
          <Route path="/projekte">
            <Projekte url={API} />
          </Route>
          <Route path="/werkzeug">
            <Maschinen maschinen={produkte["Kleinmaschinen"].produkte} categories={produkte["Kleinmaschinen"].kategorien} marken={produkte["Kleinmaschinen"].hersteller} path="/werkzeug" unterseite={Unterseiten.Kleinmaschinen} />
          </Route>
          <Route path="/sonstiges">
            <Maschinen maschinen={produkte["Sonstiges"].produkte} categories={produkte["Sonstiges"].kategorien} marken={produkte["Sonstiges"].hersteller} path="/sonstiges" unterseite={Unterseiten.Sonstiges} />
          </Route>
          <Route path="/absauganlagen">
            <Maschinen maschinen={produkte["Absauganlagen"].produkte} categories={produkte["Absauganlagen"].kategorien} marken={produkte["Absauganlagen"].hersteller} path="/absauganlagen" unterseite={Unterseiten.Absauganlagen} />
          </Route>
          <Route path="/ersatzteile">
            <Ersatzteile data={landingPageData.Contact} />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
          <Route path="/impressum">
            <Impressum></Impressum>
          </Route>
          <Route path="/">
            {/* <Header data={landingPageData.Header} /> */}
            <GridMenu></GridMenu>
            <div>
            </div>
            {/* <div className="" style={{  marginTop: "100px", marginBottom: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
              <div className="" style={{ transform: "scale(2)", display:"inline"}}>
                <Search searchText={searchText} setSearchText={setSearchText} />
              </div>
            </div> */}
            {/* <Features data={landingPageData.Features} /> */}

            {maschinen.filter(i => i["angebot_aktiv"]).length > 0 &&
              <MaschinenSliderComby title={t("Maschinen im Angebot")} subtitle={t("Es gab noch nie")} maschinen={maschinen.filter(i => i["angebot_aktiv"]).sort((a, b) => b["angebot_prioritaet"] - a["angebot_prioritaet"])} mehrLink="/maschinen?sort=angebot_aktiv" path="/maschinen"></MaschinenSliderComby>}
            <MaschinenSliderComby title={t('Beliebtesten Maschinen')} subtitle={t("Wir bieten eine")} maschinen={[...(maschinen).filter(i => i["top_maschine"])].sort((a, b) => b["top_maschine"] - a["top_maschine"])} mehrLink="/maschinen?sort=top_maschine" path="/maschinen"></MaschinenSliderComby>
            <MaschinenSliderComby title={t("Neusten Maschinen")} subtitle={t("Maschinen frisch aus der Fabrik!")} maschinen={[...maschinen].sort((a, b) => a["_created"] < b["_created"]).slice(0, 10)} mehrLink="/maschinen?sort=_created" path="/maschinen"></MaschinenSliderComby>


            {/* <About data={landingPageData.About} /> */}
            <Services data={landingPageData.Services} />

            {produkte["Kleinmaschinen"].produkte.filter(i => i["angebot_aktiv"]).length > 0 &&
              <MaschinenSliderComby title={t("Werkzeug im Angebot")} subtitle={t("Es gab noch nie so einen guten Moment jetzt zuzugreifen")} maschinen={produkte["Kleinmaschinen"].produkte.filter(i => i["angebot_aktiv"]).sort((a, b) => b["angebot_prioritaet"] - a["angebot_prioritaet"])} mehrLink="/werkzeug?sort=angebot_aktiv" path="/werkzeug"></MaschinenSliderComby>}
            <MaschinenSliderComby title={t("Topseller Werkzeug")} subtitle={t("Mit diesem Werkzeug kann man nicht viel falsch machen")} maschinen={[...(produkte["Kleinmaschinen"].produkte).filter(i => i["top_maschine"])].sort((a, b) => b["top_maschine"] - a["top_maschine"])} mehrLink="/werkzeug?sort=top_maschine" path="/werkzeug"></MaschinenSliderComby>
            <MaschinenSliderComby title={t("Neustes Werkzeug")} subtitle={t("Nigelnagelneues Werkzeug direkt von der Werkbank")} maschinen={[...produkte["Kleinmaschinen"].produkte].sort((a, b) => a["_created"] < b["_created"]).slice(0, 10)} mehrLink="/werkzeug?sort=_created" path="/werkzeug"></MaschinenSliderComby>
            <MaschinenSliderComby title={t("Beliebte Produkte")} subtitle={""} maschinen={[...produkte["Sonstiges"].produkte].sort((a, b) => a["_created"] < b["_created"]).slice(0, 10)} mehrLink="/sonstiges?sort=top_maschine" path="/sonstiges"></MaschinenSliderComby>

            <Gallery />

            {/* <Testimonials data={landingPageData.Testimonials} /> */}
            {/* <Team data={landingPageData.Team} /> */}
          </Route>
        </Switch>
        <CookieConsent enableDeclineButton>{t("Cookie MSG")}</CookieConsent>
        <Contact data={landingPageData.Contact} />
      </div>
    </Router>
  )
  function MaschinenSliderComby(props) {
    if (props.maschinen.length > 0) {
      return <div style={{ paddingBottom: "250px" }}>
        <div className=' container section-title text-center' style={{ paddingTop: "100px" }}>
          <h2>{props.title}</h2>
          <p>{props.subtitle}</p>
        </div>
        <MaschinenSlider maschinen={props.maschinen} mehrLink={props.mehrLink} path={props.path} />
      </div>
    }
    return null;
  }
}

export default App
