import React from 'react';
import { Link } from "react-router-dom";
import { MaschinenAlbum } from './MaschinenAlbum';
import { useTranslation } from 'react-i18next'

export function MaschinenSlider(props) {
    const { t } = useTranslation()

    return (
        <div style={{position:"relative"}}>
            <div className="row flex-nowrap" style={{ overflowX: "scroll", whiteSpace: "normal", display: "flex", boxShadow: "0 0 10px rgba(0, 0, 0, 0.15) inset", borderColor: "rgba(231, 231, 231, 0)", paddingTop: "60px", width: "100%" }}>
                {props.maschinen.map((item, index) => { return <MaschinenAlbum i={item} pos={index} slider={true} key={item.id} path={props.path}></MaschinenAlbum> })}
            </div>
            <Link to={props.mehrLink ? props.mehrLink : props.path } style={{position: "absolute",right: "30px",bottom: "20px"}} className="btn btn-lg btn-custom mehr-btn">{t("Mehr")}</Link>
        </div>
    )
}