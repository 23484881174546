import React from 'react';
import { useTranslation } from 'react-i18next'

export const Services = (props) => {
  const { t } = useTranslation()
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{t("Wir bieten")}</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className='row'>
          <ServiceItem icon="fa fa-user" name={t("Kundenservice")} text={t("Service für Ersatzteile, Reparaturen, Schulungen, Planungen, Schleifdienst und vieles mehr.")}></ServiceItem>
          <ServiceItem icon="fa fa-wrench" name={t("Reparaturen")} text={t("Wir kümmern uns um Sie auch nach dem Verkauf!")} ></ServiceItem>
          <ServiceItem icon="fa fa-graduation-cap" name={t("Kurse und Schulungen")} text={t("Damit Ihre Mitarbeiter immer auf dem neuesten Stand sind.")} ></ServiceItem>
          <ServiceItem icon="fa fa-folder-open" name={t("Projektplanungen")} text={t("Planen Sie in Ihrer Werkstatt etwas zu ändern? Wir unterstützen Sie von der Idee bis zur Umsetzung.")} ></ServiceItem>
          <ServiceItem icon="fa fa-plane" name={t("Lieferservice")} text={t("Wir bieten einen schnellen Lieferservice.")} ></ServiceItem>
          <ServiceItem icon="fa fa-lightbulb-o" name={t("Kompetente Beratungen")} text={t("Kompetente Beratung seit über 50 Jahren.")} ></ServiceItem>
        </div>
      </div>
    </div>
  )
  function ServiceItem(props) {
    return <div className='col-md-4'>
      {' '}
      <i className={props.icon}></i>
      <div className='service-desc'>
        <h3>{props.name}</h3>
        <p>{props.text}</p>
      </div>
    </div>
  }
}
