import { Link } from "react-router-dom";
import React  from 'react';
import { useTranslation } from 'react-i18next'

export function GridMenu() {
    const { t } = useTranslation()
    return (
        <div className="container">
            <div className='row'>
                <GridMenuItem text={t("Neumaschinen")}  img="01 Neumaschinen.png"  link={"/maschinen?zustand=N"}></GridMenuItem>
                <GridMenuItem text={t("Gebrauchtmaschinen")}  img="02 Gebrauchtmaschinen.png" link={"/maschinen?zustand=G"}></GridMenuItem>
                <GridMenuItem text={t("Ersatzteile")}  img="03 Ersatzteile.png" link={"/ersatzteile"}></GridMenuItem>
                <GridMenuItem text={t("Service - Reparaturen")}  img="04 Service.png" link={"/#contact"}></GridMenuItem>

                <GridMenuItem text={t("Kleinmaschinen")}  img="05 Kleinmaschinen.png" link={"/werkzeug"}></GridMenuItem>
                <GridMenuItem text={t("Sonstiges")}  img="06 Sonstiges.png" link={"/sonstiges"}></GridMenuItem>
                {/* <GridMenuItem text="Absauganlagen" kid="Abricht - Dickenhobelmaschine" img="07 Absauganlagen.png" link={"/absauganlagen"}></GridMenuItem> */}
                <GridMenuItem text={t("Absauganlagen")} img="07 Absauganlagen.png" link={"/absauganlagen"}></GridMenuItem>
                <GridMenuItem text={t("Über uns - Kontakt")}  img="08 Uns.png" link={"/about"}></GridMenuItem>

                {/* <GridMenuItem text="fresare" kid="CNC Bearbeitungszentrum" img="Fresare.png"></GridMenuItem>
                <GridMenuItem text="pressare" kid="CNC Bearbeitungszentrum" img="Pressare.png"></GridMenuItem>
                <GridMenuItem text="profilare" kid="CNC Bearbeitungszentrum" img="Profilare.png"></GridMenuItem>
                <GridMenuItem text="tagliare / sezionare" kid="CNC Bearbeitungszentrum" img="Tagliare-sezionare.png"></GridMenuItem> */}
            </div>
        </div>
    );
    function GridMenuItem(props) {
        return (
            <div className='col-sm-6 col-md-4 col-lg-3 grid-menu-item' style={{ position: 'relative', padding: "0" }}>
                <Link to={props.link}>
                    <img src={'img/grid/' + props.img} alt='Project Title' className="werkzeug-image" />
                    <p className='grid-menu-item-overlay'>{props.text}</p>
                </Link>
            </div>
        );
    }
}