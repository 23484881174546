import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function AboutUs() {
    const { t } = useTranslation()
    return (
        <div className="container margintp">
            <div className="three-fourth">
                <h4>{t("Unsere Firmengeschichte")}</h4>
                <p>
                    {t("1958 beschloss Albert Micheli")}
                </p><br />
                {/* <img
                    src='img/historical.jpeg'
                    className='img-responsive'
                    alt='Project Title'
                    style={{maxWidth:"100%"}}
                />   */}
                <h4>{t("Ein Schicksalsschlag")}</h4>
                <p>{t("Doch genau im Jahr des 30.")}</p><br />
                <h4>{t("Mimac - Micheli Maschinen heute")}</h4>
                <p style={{marginBottom:"100px"}}>{t("Dass dies die richtige Entscheidung war")}</p>

            </div>
        </div>
    )
}