import React  from 'react';

export const MarkenGrid = (props) => {
    // function Marke(props){
    //     return <div className='col-sm-6 col-md-4 col-lg-4 no-padding-mobile'>
    //         <div className='portfolio-item'>
    //             <div className='hover-bg'>
    //                 {' '}
    //                 <a
    //                     href={"/maschinen?hersteller="+ props.hersteller}
    //                     // title='Project Title'
    //                     data-lightbox-gallery='gallery1'
    //                 >
    //                     <div className='hover-text'>
    //                     </div>
    //                     <img
    //                         src={'img/marken/' + props.img}
    //                         className='img-responsive gallery-img'
    //                         alt={props.hersteller}
    //                     />{' '}
    //                 </a>{' '}
    //             </div>
    //         </div>
    //     </div>;
    // }
    function Marke(props) {
        return <div className="logo-box col-sm-6 col-md-4 col-lg-4 no-padding-mobile">
            <a href={"/suche?suchtext=" + props.hersteller} >
                <img
                    src={'img/marken/' + props.img}
                    className='img-responsive gallery-img'
                    alt={props.hersteller} />
            </a>
        </div>
    }
    return (
        <div className='row'>
            <Marke img="abac.webp" hersteller="Abac"></Marke>
            <Marke img="altendorf.png" hersteller="Altendorf"></Marke>
            <Marke img="balma.webp" hersteller="Balma"></Marke>
            <Marke img="barth.png" hersteller="Barth"></Marke>
            <Marke img="bessey.png" hersteller="Bessey"></Marke>
            <Marke img="casadei.png" hersteller="Casadei"></Marke>
            <Marke img="centauro.jpg" hersteller="Centauro"></Marke>
            <Marke img="dewalt.png" hersteller="Dewalt"></Marke>
            <Marke img="duo_fast.png" hersteller="Duo Fast"></Marke>
            <Marke img="elcon.png" hersteller="Elcon"></Marke>
            <Marke img="fein.png" hersteller="Fein"></Marke>
            <Marke img="fervi.png" hersteller="Fervi"></Marke>
            <Marke img="fisch_tools.png" hersteller="Fisch Tools"></Marke>
            <Marke img="formec.png" hersteller="Formec"></Marke>
            <Marke img="hess.png" hersteller="Hess"></Marke>
            <Marke img="hm_muellner.png" hersteller="HM Müllner"></Marke>
            <Marke img="hoefer_presstechnik.png" hersteller="Höfer Presstechnick"></Marke>
            <Marke img="holz_her.png" hersteller="Holz Her"></Marke>
            <Marke img="knapp_verbinder.png" hersteller="Knapp"></Marke>
            <Marke img="kundig.png" hersteller="Kundig"></Marke>
            <Marke img="kuper.png" hersteller="Kuper"></Marke>
            <Marke img="lft.png" hersteller="LFT"></Marke>
            <Marke img="maggi.png" hersteller="maggi"></Marke>
            <Marke img="makita.png" hersteller="Makita"></Marke>
            <Marke img="minimax.png" hersteller="minimax"></Marke>
            <Marke img="morbidelli.png" hersteller="morbidelli"></Marke>
            <Marke img="nestro.png" hersteller="Nestro"></Marke>
            <Marke img="omc.png" hersteller="OMC"></Marke>
            <Marke img="omga.png" hersteller="Omga"></Marke>
            <Marke img="putsch_meniconi.png" hersteller="Putsch"></Marke>
            <Marke img="renner.webp" hersteller="Renner Kompressoren"></Marke>
            <Marke img="scm.png" hersteller="SCM"></Marke>
            <Marke img="sicar.png" hersteller="Sicar"></Marke>
            <Marke img="stehle.png" hersteller="Stehle"></Marke>
            <Marke img="stromab.png" hersteller="Stromab"></Marke>
            <Marke img="stubai.png" hersteller="Stubai"></Marke>
            <Marke img="tormek.png" hersteller="Tormek"></Marke>
            <Marke img="ulmia.png" hersteller="Ulmia"></Marke>
        </div>
    )
}