import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import React, { useState, useEffect } from 'react';

export function Projekte(props) {
    const [projekte, setProjekte] = useState([]);
    
    useEffect(() => {
        function loadData(baseUrl){
          let api_key = "USR-1c4f92b9390a6a3be05f4f358372a5206841d14c"
          console.log("trying to fetch ", baseUrl)
          let init = { headers: { "api-key" : api_key } }
          fetch(baseUrl, init)
            .then(res => {console.log(res); return res.json()})
            .then((result) => {setProjekte(result ? result.reverse() : result)})
        }
        loadData(props.url + "projekte")
    }, [])

    return (
        <div className="container">
            <div className="text-center section-title">
                <h2>Unsere Projekte</h2>
            </div>
            {projekte.map((p, idx) => <Projekt key={idx} p={p}></Projekt>)}

            <p>
                1958 beschloss Albert Micheli nach abgeschlossener Lehre und einiger Erfahrung mit dem Verkauf von Maschinen für Handwerk und Industrie, seine Geschäfte selbst in die Hand zu nehmen und eine eigene Firma zu gründen. So entstand in der Rittner Straße in Bozen ein kleines Geschäft für die Vermarktung von hochqualitativen Maschinen und Geräten für die Holzverarbeitung, das 1966 in die Andreas-Hofer-Straße erweitert werden musste. 1973 folgte die Übersiedelung in die Schlachthofstraße 91-93, wo sich noch heute der Firmensitz befindet. Gleichzeitig engagierte sich der aktive junge Unternehmer, der auch in der Kolpingfamilie tätig war, mit einigen Freunden, darunter Holzfachexperte Karl Pichler, für die Ausbildung vom Nachwuchs im Bereich Holz und unterstützte die Errichtung der Fachschule für maschinelle Holzverarbeitung in Meran. Albert Micheli, der eine ausgesprochen zugängliche und charmante Persönlichkeit war, zeigte sich schon in den 60er- und 70er-Jahren innovativ und baute das Image seines Betriebes auf die persönliche Kundenbindung. Bald wurde die Firma Micheli Maschinen für alle im Holzsektor tätigen Handwerker ein Begriff für Auswahl, kompetente Beratung, Spitzenqualität und verlässliche Serviceleistung.
            </p><br />
            <div style={{textAlign:"center"}}>
                <img
                    src='img/historical.jpeg'
                    // className='img-responsive'
                    alt='Project Title'
                    style={{maxWidth:"100%"}}
                />
            </div>

            <div className="text-center section-title">
                <h2>Absauganlage</h2>

            </div>
            <p>
                On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.
            </p>
            <div className="text-center section-title">
                <h2>Spritzkabine</h2>
            </div>
            <p>
                On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.
            </p>
            <div className="mt-5 mb-5" style={{display:"flex", justifyContent:"center"}}>
                <Carousel
                images={[{src:"https://mimac.it/content/uploads/images/6107e43361b19-6107e43361b5b.jpg"},{src:"https://mimac.it/content/uploads/images/6107e43361b19-6107e43361b5b.jpg"}]}
                style={{ height: 500, width: 800 }}
                shouldMaximizeOnClick={true}
                shouldMinimizeOnClick={true}
                hasIndexBoard={false}
                hasMediaButton={false}
                />
            </div>


        </div>

    )

    function Projekt(props) {
        console.log(props.p["Inhalt"])
        return (<React.Fragment>
            <div className="">
                <h1>{props.p["Titel"]}</h1>
            </div>
            <div className="row " style={{ marginTop: "100px" }}>
                <div className='beschreibung' dangerouslySetInnerHTML={{ __html: props.p["Inhalt"] }} />
            </div>
        </React.Fragment>
        )
    }
}