import React from 'react';
import {
    useRouteMatch,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
Modal.setAppElement('#root');

export function Popup(props) {
        const { t } = useTranslation()

    const [modalIsOpen, setIsOpen] = React.useState(true);


    function closeModal() {
        setIsOpen(false);
    }

    function dontShowInFuture(){
        localStorage.setItem(props.p._id, true);
    }

    return (
        (props.p && props.p.title && localStorage.getItem(props.p._id) == null) ?
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <a onClick={closeModal} className='close'></a>

                <h3>{props.p.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: props.p.description }}></div>
                <div><button type="button" className="btn btn-secondary" onClick={dontShowInFuture} style={{float:"right"}}>{t("Nicht mehr anzeigen")}</button></div>
            </Modal>
            : <React.Fragment></React.Fragment>
    );
}