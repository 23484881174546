import React, { useState, useEffect } from 'react';
import './Maschinen.css';
import { Unterseiten } from './MaschinenDetails'
import { MaschinenAlbum } from './MaschinenAlbum'
import { Search } from './Search';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next'



export const kategorienReset = "Alle Kategorien";
export const herstellerReset = "Alle Hersteller";

export function MaschinenGrid(props) {
  const { t } = useTranslation()
  
  const history = useHistory();
  const [kategorie, setKategorie] = useState(kategorienReset);
  const [hersteller, setHersteller] = useState(herstellerReset);
  const [gebraucht, setGebraucht] = useState('');
  const [suchtext, setSuchtext] = useState("");
  const [sortText, setSortText] = useState("");

  let query = useQuery();

  useEffect(() => {
    setKategorie(decodeURI(query.get("kategorie") ? query.get("kategorie") : kategorienReset))
    setHersteller(decodeURI(query.get("hersteller") ? query.get("hersteller") : herstellerReset))
    setGebraucht(decodeURI(query.get("zustand") ? query.get("zustand") : ""))
    setSuchtext(decodeURI(query.get("suchtext") ? query.get("suchtext") : ""))
    setSortText(decodeURI(query.get("sort") ? query.get("sort") : ""))

    console.log("Effect:" , kategorie, hersteller, gebraucht, suchtext)
  }, [query]);  

  return (
      <React.Fragment>


          <h3>{getTitel(props.unterseite)}  {kategorie !== kategorienReset && " - " + kategorie}</h3>

          <div className='row mt-3 mb-3 maschinenfilter'>
            {props.unterseite === Unterseiten.Maschinen && 
              <div className='col-md'>
                <select value={gebraucht} onChange={e => {setGebraucht(e.target.value);history.push({pathname: props.path, search: searchParamString(hersteller, kategorie, e.target.value, suchtext) })}} className="form-select">
                  <option value="">{t("Alle Maschinen")}</option>
                  <option value="N">{t("Neue Maschinen")}</option>
                  <option value="G">{t("Gebrauchte Maschinen")}</option>
                </select>
              </div>
            }
            <div className='col-md'><CatList i={props.categories}></CatList></div>
            <div className='col-md'><MarkenList i={props.marken}></MarkenList></div>
            <div className='col-md'>
              <select value={sortText} onChange={e => {setSortText(e.target.value); history.push({pathname: props.path, search: searchParamString(hersteller, kategorie, gebraucht, suchtext, e.target.value) })}} className="form-select">
                <option value="">{t("Sortieren")}</option>
                <option value="_created">{t("Neuheiten")}</option>
                <option value="top_maschine">{t("Beliebt")}</option>
                <option value="angebot_aktiv">{t("Angebote")}</option>
                <option value="Hersteller">{t("Hersteller")}</option>
                <option value="Kategorie">{t("Kategorie")}</option>
              </select>
            </div>
            <div className='col-md'><Search suchtext={suchtext} setSuchtext={setSuchtext} onEnter={onEnter}/></div>
          </div>
          <div className="row row-cols-sm-2">
            {mapMaschinen()}
          </div>
      </React.Fragment>
    );
  
  function mapMaschinen(){
    let x = sortieren(markeFilter(catFilter(suche(gebrauchtFilter(props.maschinen, gebraucht), suchtext)))).map(item => { return <MaschinenAlbum i={item} key={item.id} path={props.path}></MaschinenAlbum> })
    if(x.length > 0){
      return x;
    }else{
      return <div className='col-xs-12 col-sm-12' style={{textAlign:"center"}}>{nichtGefunden(props.unterseite)}</div>;
    }
  }

  function getTitel(unterseite){
    switch(unterseite){
      case Unterseiten.Maschinen: return t("Maschinen");
      case Unterseiten.Kleinmaschinen: return t("Kleinmaschinen");
      case Unterseiten.Sonstiges: return t("Sonstiges");
      case Unterseiten.Absauganlagen: return t("Absauganlagen");
    }
  }

  function nichtGefunden(unterseite){
    if(unterseite === Unterseiten.Kleinmaschinen){
      return t("Keine Kleinmaschinen gefunden");
    } else if (unterseite === Unterseiten.Maschinen){
      return t("Keine Maschinen gefunden");
    } else {
      return t("Keine Produkte gefunden");
    }
    
  }

  function sortieren(arr){
    console.log("Sortieren nach ", sortText)
    if (sortText === t("Hersteller")){
      return arr.sort((a,b) => a[sortText] > b[sortText])
    } else {
      if (sortText){
        return arr.sort((a,b) => a[sortText] < b[sortText])
      }
    }
    return arr
  }

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  function onEnter(x){
    console.log("onEnter", x)
    setSuchtext(x)
    history.push({pathname: props.path, search: searchParamString(hersteller, kategorie, gebraucht, x) })
  }

  function FilterList(props) {
    return <div className="mb-4">
      {props.i.map(i => <button className="filter-btn btn btn-outline-secondary" key={i} onClick={_ => { setGebraucht(''); setKategorie(i); }}>{i}</button>)}
    </div>
  }

  function CatList(props) {
    console.log("props",props)
    return <select value={kategorie} onChange={e => {setKategorie(e.target.value); history.push({pathname: props.path, search: searchParamString(hersteller, e.target.value, gebraucht, suchtext) })}} className="form-select">
      {props.i.map(i => <option value={i} key={i}>{i === kategorienReset ? t(kategorienReset) : i}</option>)}
    </select>
  }

  function MarkenList(props) {
    return <select value={hersteller} onChange={e =>{setHersteller(e.target.value); history.push({pathname: props.path, search: searchParamString(e.target.value, kategorie, gebraucht, suchtext) })}} className="form-select">
      {props.i.map(i => <option value={i} key={i}>{i === herstellerReset ? t(herstellerReset) : i}</option>)}
    </select>
  }

  function searchParamString(herstellerP, kategorieP, gebrauchtP, suchtextP, sortTextP){
    const params = new URLSearchParams({
      hersteller: herstellerP,
      kategorie: kategorieP,
      zustand: gebrauchtP,
      suchtext: suchtextP
    });
    if(sortTextP)
      params.append("sort", sortTextP)
    return params.toString()
  }

  function gebrauchtFilter(maschinen, gebraucht) {
    return maschinen.filter(i => i.type.includes(gebraucht));
  }

  function catFilter(maschinen) {
    if (kategorie === null)
      return maschinen
    return maschinen.filter(i => kategorie === kategorienReset || ("cat_name" in i && i.cat_name.localeCompare(kategorie) === 0));
  }

  function markeFilter(maschinen) {
    if (hersteller === null)
      return maschinen;
    return maschinen.filter(i => hersteller === herstellerReset || i.marke.localeCompare(hersteller) === 0);
  }

  function suche(maschinen, text) {
    console.log("Suche", text, maschinen)
    if(text)
      return maschinen.filter(m => contains2(m, text.toLowerCase()));
    return maschinen
  }

  function contains(maschine, text) {
    for (const [key, value] of Object.entries(maschine)) {
      if (value.toLowerCase().includes(text))
        return true;
      // console.log(`${key}: ${value}`);
    }
    return false;
  }
  function contains2(maschine, text) {
    for (const key of ["Bezeichnung", "Beschreibung", "Hersteller", "Kartenbeschreibung" ,"Kategorie", "Kurzbeschreibung", "Lieferstatus", "Modell", "Zustand", "artikelnummer"]) {
      // console.log("key", key, maschine)
      // if(!(key in maschine)){
      //   console.log("found", key, maschine)
      // }
      if (maschine[key] && maschine[key].toLowerCase().includes(text))
        return true;
      // console.log(`${key}: ${value}`);
    }
    return false;
  }
}